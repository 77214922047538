import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
      <article className="vh-100 dt w-100 bg-light-gray">
    <div className="dtc v-mid tc white ph3 ph4-l">
      <h1 className="f6 f2-m f-subheadline-l fw6 tc black">*</h1>
    </div>
  </article>
  </Layout>
)

export default IndexPage
